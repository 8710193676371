import { query } from "@/utils/api-utils";
import { request } from ".";
import { AuthParams } from "./account";

export const facebookApi = {
  getAllInfo: () => request("/facebook/me"),
  getConnectLink: (redirect_uri: string, rewrite?: boolean) => request("/facebook/connect"+query({ redirect_uri, rewrite })),
  connectToFacebook: ({ code, state, redirect_uri }: AuthParams) => request("/facebook/connect"+query({ redirect_uri }), { code, state }),
  
  disconnect: (body: { id: number }[]) => request("/facebook/disconnect", body),
  bms_enable: (body: { id: number }[]) => request("/facebook/businesses-enable", body),
  bms_disable: (body: { id: number }[]) => request("/facebook/businesses-disable", body),
  ad_accounts_enable: (body: { id: number }[]) => request("/facebook/ad-accounts-enable", body),
  ad_accounts_disable: (body: { id: number }[]) => request("/facebook/ad-accounts-disable", body),
  pages_enable: (body: { id: number }[]) => request("/facebook/pages-enable", body),
  pages_disable: (body: { id: number }[]) => request("/facebook/pages-disable", body),
  pages_ai_moderation_enable: (body: { id: number }[]) => request("/facebook/pages-ai-moderation-enable", body),
  pages_ai_moderation_disable: (body: { id: number }[]) => request("/facebook/pages-ai-moderation-disable", body),
  bms_ai_moderation_enable: (body: { id: number }[]) => request("/facebook/businesses-ai-moderation-enable", body),
  bms_ai_moderation_disable: (body: { id: number }[]) => request("/facebook/businesses-ai-moderation-disable", body),
  pages_ai_engagement_enable: (body: { id: number }[]) => request("/facebook/pages-ai-engagement-enable", body),
  pages_ai_engagement_disable: (body: { id: number }[]) => request("/facebook/pages-ai-engagement-disable", body),
  bms_ai_engagement_enable: (body: { id: number }[]) => request("/facebook/businesses-ai-engagement-enable", body),
  bms_ai_engagement_disable: (body: { id: number }[]) => request("/facebook/businesses-ai-engagement-disable", body),

  syncBm: (businessId: number) => request(`/facebook/business-sync/${businessId}`, {}),
  sync: (accountId: number) => request(`/facebook/sync/${accountId}`, {})

}