import { blockListsApi } from "@/api/block-lists";
import { commentsApi } from "@/api/comments";
import { replace } from "@/utils/vue";
import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useDataStore } from "./data";
import { useUiStore } from "./ui";

type Comment = {
  id: number,
  blocks: { id: number, name: string }[],
  comment_count: number,
  comment_hide_count: number,
  comment_modereted: number,
  comment_replied_count: number,
  comment_remove_count: number,
  status_id: number,
  group_id: number,
  is_ai_moderation_active: boolean,
  is_ai_engagement_active: boolean
}

export const useCommentsStore = defineStore('comments', () => {

  const data = useDataStore()
  const comments = reactive<Comment[]>([])
  const loadedData = new Set()
  const uiStore = useUiStore()
  const loaded = ref(false)

  const requestComments = async (type: string, reloadAll: boolean) => {
    const params = uiStore.getDateParam()
    params.groupby_id = [ "bm", "pages", "ad_accounts" ].indexOf(type)+1

    if (reloadAll) loadedData.clear()
    if (loadedData.has(type)) return

    loadedData.add(type)
    loaded.value = false
    const resp = await commentsApi.getAll(params)
    for (let item of resp.elements) {
      item.group_id = params.groupby_id
    }
    
    if (!reloadAll) {
      comments.push(...resp.elements)
    } else {
      replace(comments, resp.elements)
    }

    loaded.value = true
  }

  const applyBlockList = async (selected: any[], blockList: any) => {
    for (let itemId of selected) {
      const comment = comments.find(_item => _item.id === itemId)
      if (!comment) continue

      comment.blocks.push(blockList)
    }
  }

  const connectBlockList = async (selected: number[], blockList: any) => {
    const _selected = [...selected]
    const businesses = selected.map(id => data.businesses.find(_item => _item.id === id)).filter(item => !!item)
    for (let business of businesses) {
      for (let page of business!.pages) {
        if (selected.includes(page.id)) continue
        selected.push(page.id)
      }
      for (let ad_account of business!.ad_accounts) {
        if (selected.includes(ad_account.id)) continue
        selected.push(ad_account.id)
      }
    }
    for (let id of _selected) {
      const item = data.pages.find(item => item.id === id) || data.ad_accounts.find(item => item.id === id)
      if (!item || !item.bm || selected.includes(item.bm.id)) continue
      selected.push(item.bm.id)
      businesses.push(item.bm)
    }
    applyBlockList(selected, blockList)
    
    const business_ids = businesses.map(item => item!.id)
    const page_ids = selected.filter(id => data.pages.find(_item => _item.id === id))
    const ad_account_ids = selected.filter(id => data.ad_accounts.find(_item => _item.id === id))

    await blockListsApi.connect(blockList.id, { business_ids, page_ids, ad_account_ids })
  }

  const removeBlockList = async (selected: number[], blockList: any) => {
    const businesses = selected.map(id => data.businesses.find(_item => _item.id === id)).filter(item => !!item)
    for (let business of businesses) {
      for (let page of business!.pages) {
        if (selected.includes(page.id)) continue
        selected.push(page.id)
      }
      for (let ad_account of business!.ad_accounts) {
        if (selected.includes(ad_account.id)) continue
        selected.push(ad_account.id)
      }
    }

    const arr: Comment[] = []
    for (let id of selected) {
      const comment = comments.find(_item => _item.id === id)
      if (comment) {
        arr.push(comment)
      } 
    }
    for (let item of arr) {
      const index = item.blocks.findIndex(item => item.id === blockList.id)
      if (index === -1) continue
      item.blocks.splice(index, 1)
    }
    
    const business_ids = businesses.map(item => item!.id)
    const page_ids = selected.filter(id => data.pages.find(_item => _item.id === id))
    const ad_account_ids = selected.filter(id => data.ad_accounts.find(_item => _item.id === id))
    await blockListsApi.disconnect(blockList.id, { business_ids, page_ids, ad_account_ids })
  }

  const removeBlockListFromItems = async (selected: any[]) => {
    const blockIds = new Map<number, number[]>()

    for (let item of selected) {
      const comment = comments.find(_item => _item.id === item.id)
      if (!comment) continue

      for (let block of comment.blocks) {
        if (blockIds.has(block.id)) {
          blockIds.get(block.id)!.push(item.id)
        } else {
          blockIds.set(block.id, [ item.id ])
        }
      }
    }

    for (let [ blockListId, selected ] of blockIds) {
      const business_ids = selected.filter(item => !!data.businesses.find(_item => _item.id === item))
      const page_ids = selected.filter(item => !!data.pages.find(_item => _item.id === item))
      const ad_account_ids = selected.filter(item => !!data.ad_accounts.find(_item => _item.id === item))

      await blockListsApi.disconnect(blockListId, { business_ids, page_ids, ad_account_ids })
    }

    for (let item of selected) {
      const comment = comments.find(_item => _item.id === item.id)
      if (!comment) continue
      comment.blocks = []
    }
  }

  const reset = () => {
    comments.length = 0
    loaded.value = false
  }

  return {
    comments,
    loaded,
    requestComments,
    applyBlockList,
    connectBlockList,
    removeBlockList,
    removeBlockListFromItems,
    reset
  }
})